export const LOAD_GENERAL = "LOAD_GENERAL";
export const LOAD_BANNER = "LOAD_BANNER";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_MENUS = "LOAD_MENUS";

// pages load types
export const LOAD_FAQ = "LOAD_FAQ";
export const LOAD_ABOUT = "LOAD_ABOUT";
export const LOAD_SINGLE = "LOAD_SINGLE";
export const LOAD_CONTACT = "LOAD_CONTACT";

// load products
export const LOAD_PRODUCT_DETAILS = "LOAD_PRODUCT_DETAILS";

// control errors
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const GQL_ERRORS = "GQL_ERRORS";

// control loading
export const GLOBAL_LOADING = "GLOBAL_LOADING";
export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const BANNER_LOADING = "BANNER_LOADING";
export const CAT_PRODUCT_LOADING = "CAT_PRODUCT_LOADING";
export const SEARCH_PRODUCT_LOADING = "SEARCH_PRODUCT_LOADING";
export const PRODUCT_DETAILS_LOADING = "PRODUCT_DETAILS_LOADING";
export const DESCRIPTION_LOADING = "DESCRIPTION_LOADING";
export const SELLER_INFO_LOADING = "SELLER_INFO_LOADING";
export const PAGE_LOADING = "PAGE_LOADING";
export const ADDRESS_LOADING = "ADDRESS_LOADING";
export const PLACE_LOADING = "PLACE_LOADING";

// virtual Cart actions
export const ADD_PRODUCT_VIRTUAL = "ADD_PRODUCT_VIRTUAL";
export const REMOVE_PRODUCT_VIRTUAL = "REMOVE_PRODUCT_VIRTUAL";
export const ADD_CATEGORY_VIRTUAL = "ADD_CATEGORY_VIRTUAL";
// cart actions
export const SELECT_CONFIGURED = "SELECT_CONFIGURED";
export const SELECT_ATTRIBUTE = "SELECT_ATTRIBUTES";
export const SELECT_SHIPPING_ADDRESS = "SELECT_SHIPPING_ADDRESS";
export const SELECT_BILLING_ADDRESS = "SELECT_BILLING_ADDRESS";
export const SELECT_CONFIGURED_ITEMS = "SELECT_CONFIGURED_ITEMS";
export const SELECT_ADVANCE_PERCENT = "SELECT_ADVANCE_PERCENT";
export const SELECT_DISCOUNT_PERCENT = "SELECT_DISCOUNT_PERCENT";
export const SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD";
export const ADD_COUPON_DETAILS = "ADD_COUPON_DETAILS";

export const SET_USER = "SET_USER";
export const OTP_SUBMIT = "OTP_SUBMIT";
export const CHECK_USER = "CHECK_USER";
export const WISHLIST = "WISHLIST";
export const SET_SHOP_USER = "SET_SHOP_USER";

export const LOAD_FAKE = "LOAD_FAKE";

export const LOAD_INIT = "LOAD_INIT";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PAGES = "LOAD_PAGES";
